/*.App {*/
/*  !*color: #4B2DA6;*!*/
/*  text-align: center;*/
/*  align-items: center;*/
/*  justify-content: flex-start;*/
/*}*/

.Home {
  background-color: #FFFBFE;
}

.center {
  text-align: center;
}

.box {
  display: grid;
  /*grid-template-columns: repeat(3, 1fr);*/
  margin-top: 80px;
  gap: 20px;

}
.item {
  background-color: #E8DEF8;
  margin-left: 120px;
  margin-right: 120px;
  padding: 0;
  text-align: center;
  border-radius: 50px;
  border: solid; border-color: #6750A4;
}
ul {
  list-style-type: none;
  text-align: center;
  /*font-size: large;*/
  padding-left: 0;
}
li {
  margin-bottom: 10px;
}

.IconButton {
  background-color: #6750A4;
  border-radius: 50px;
}

.AppBar {
  color: #6750A4;
}


/*.test {*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  margin-top: 80px;*/
/*  border-radius: 50px;*/
/*  background-color: #E8DEF8*/

/*}*/

/*.testdiv {*/
/*  border-radius: 50px;*/
/*  background-color: #E8DEF8*/


/*}*/